@import 'landingFiles/fonts/fonts.css';

@import 'swiper/swiper-bundle.css';
@import 'landingFiles/landing';
@import 'src/assets/styles/common';
@import 'landingFiles/variables';

body {
  font-family: 'Yandex Sans Text', sans-serif;
  background: $bodyBg;

  .btn-primary {
    --bs-btn-hover-bg: #{lighten($primary, 20%)};
  }
}


