// Custom Variables
$customDanger: #ED2E7E;
$blue: #025BFF;
$primary: #000000; // Основной цвет
$btn-primary-color: #fff; // Цвет текста в кнопке
$asphalt: #1D2939;
$gray1: #F2F4F7;
$gray2: #E4E7EC;
$gray3: #98A2B3;
$systemNotification: #EFF8FF;
$alertWarning: #FFFAEB;
$customBorderRadius: 16px;
$bodyBg: #F2F4F7; // Цвет фона
$pageTitleColor: #626F80;
$customHoverBorder: #626F80; // Цвет border при наведении и фокусе контрола

// Переопределяем размеры контейнера
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

:root {
  --bs-card-spacer-x: 1.5rem;
  --bs-card-cap-padding-x: 1.5rem;
  --bs-breadcrumb-divider: '|';
  --bs-secondary-color: $gray3;
  --bs-breadcrumb-divider-color: $gray3;
  --bs-danger: $blue;
  --icon-fill-color: $primary;
}
