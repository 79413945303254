@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.page-title {
  color: $pageTitleColor;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  @include media-breakpoint-down(sm) {
    font-size: 20px;
    line-height: 30px;
  }
}

svg {
  fill: $primary;
}

// Pages
// Main
.head-text {
  color: $asphalt;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  @include media-breakpoint-down(sm) {
    font-size: 14px;
    line-height: 22px;
  }
}

.secondary-text {
  color: $gray3;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  @include media-breakpoint-down(sm) {
    font-size: 12px;
    line-height: 18px;
  }
}

// LegalForm
.legal-btn {
  height: 48px!important;
  @include media-breakpoint-down(sm) {
    height: 32px!important;
  }
}

// end LegalForm

// License

// end License
// end Main

// Override bootstrap
.btn {
  border-radius: $customBorderRadius;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  height: 60px;
  padding: 0 23px;
  @include media-breakpoint-down(sm) {
    height: 48px;
  }

  svg {
    fill: var(--bs-btn-color);
  }

  &:hover {
    svg {
      fill: var(--bs-btn-hover-color);
    }
  }

  &.disabled, &:disabled {
    cursor: wait;
  }
}

.btn-primary {
  --bs-btn-color: #ffffff; // Белый цвет текста
  --bs-btn-bg: #{$primary}; // Основной цвет фона
  --bs-btn-border-color: #{$primary};
  --bs-btn-hover-color: #ffffff; // Белый цвет текста при hover
  --bs-btn-hover-bg: #{darken($primary, 10%)};
  --bs-btn-hover-border-color: #{darken($primary, 10%)};
  --bs-btn-active-color: #ffffff; // Белый цвет текста при active
  --bs-btn-active-bg: #{darken($primary, 15%)};
  --bs-btn-active-border-color: #{darken($primary, 15%)};
  --bs-btn-disabled-color: #ffffff; // Белый цвет текста при disabled
  --bs-btn-disabled-bg: #{$primary};
  --bs-btn-disabled-border-color: #{$primary};
}

.btn-outline-primary {
  --bs-btn-color: #{$primary}; // Основной цвет текста
  --bs-btn-border-color: #{$primary}; // Основной цвет границы
  --bs-btn-hover-color: #ffffff; // Белый цвет текста при hover
  --bs-btn-hover-bg: #{$primary}; // Основной цвет фона при hover
  --bs-btn-hover-border-color: #{$primary}; // Основной цвет границы при hover
  --bs-btn-active-color: #ffffff; // Белый цвет текста при active
  --bs-btn-active-bg: #{darken($primary, 15%)}; // Основной цвет фона при active
  --bs-btn-active-border-color: #{darken($primary, 15%)}; // Основной цвет границы при active
  --bs-btn-disabled-color: #{$primary}; // Основной цвет текста при disabled
  --bs-btn-disabled-bg: transparent; // Прозрачный фон при disabled
  --bs-btn-disabled-border-color: #{$primary}; // Основной цвет границы при disabled
}

.btn-lg {
  height: 60px;
  @include media-breakpoint-down(sm) {
    height: 48px;
  }
}

.btn-sm {
  border-radius: 12px;
  height: 32px;
}

.btn-outline-primary, .btn-outline-danger {
  border-width: 2px;
  padding: 0 22px;
}

.btn-outline-danger {
  &:hover {
    svg {
      fill: $white;
    }
  }

  &:active {
    svg {
      fill: $white;
    }
  }
}

.btn-outline-danger.btn-remove-driver {
  color: $customDanger;
  border-color: $customDanger;

  svg {
    fill: $customDanger;
  }

  &:hover {
    background: darken($customDanger, 10%) !important;
    border-color: darken($customDanger, 12.5%) !important;
    color: #fff;

    svg {
      fill: $white;
    }
  }

  &:active {
    background: darken($customDanger, 15%) !important;
    border-color: darken($customDanger, 17.5%) !important;
    color: #fff;

    svg {
      fill: $white;
    }
  }
}

.form-floating > .form-control, .form-control {
  height: 60px;
  border-width: 2px;
  border-radius: $customBorderRadius;
  font-size: 15px;

  &:focus {
    box-shadow: none;
    border-color: $customHoverBorder;
  }
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
  max-width: 118%;
}

.form-floating > label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: none;
}

.alert {
  border-radius: $customBorderRadius;
  border: none;

  color: #626F80;
  font-style: normal;
  font-weight: 500;
  @include media-breakpoint-down(sm) {
    font-size: 12px;
    line-height: 20px;
    padding: 10px;
  }

  &.alert-primary {
    background: $systemNotification;
  }

  &.alert-warning {
    background: $alertWarning;
  }

  &.alert-secondary {
    background: $gray1;
  }

  .olList {
    margin-bottom: 0;

    li {
      margin-bottom: 5px;
      line-height: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.form-check {
  label {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    padding-left: 10px;
    @include media-breakpoint-down(sm) {
      font-size: 12px !important;
      line-height: 18px;
      padding-left: 5px;
      padding-top: 3px;
    }
  }


}

.form-switch {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  background: $gray1;
  border-radius: $customBorderRadius;

  label {
    text-align: left;
    display: block;
    width: 100%;
    padding: 18px 15px;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    border: none;
    transition: border-color 0.15s ease-in-out, box-shadow-sm 0.15s ease-in-out;
    height: auto;
    @include media-breakpoint-down(sm) {
      font-size: 12px;
      line-height: 20px;
      padding: 14px 15px;
    }
  }

  .form-check-input {
    width: 48px;
    height: 26px;
    margin: 0 15px 0 0 !important;
    &:focus {
      box-shadow: none;
    }
  }

  &.form-check-reverse {
    padding-right: 0 !important;
    padding-left: 0;
  }
}

.rounded-4 {
  border-radius: 32px !important;
}

.custom-rounded {
  border-radius: 32px !important;
  @include media-breakpoint-down(sm) {
    border-radius: 16px !important;
  }
}

// end Override bootstrap

// Calendar
.bs-datepicker {
  box-shadow: 0 0 0 0 #aaa;
}

.bs-datepicker-container {
  padding: 0;
}

.theme-default .bs-datepicker-head {
  background-color: $blue;
}

.theme-default .bs-datepicker-body table td span.selected,
.theme-default .bs-datepicker-body table td.selected span,
.theme-default .bs-datepicker-body table td span[class*=select-]:after,
.theme-default .bs-datepicker-body table td[class*=select-] span:after {
  background-color: $blue;
}

// end Calendar

// Dropdown
.dropdown-menu {
  width: 100%;
  height: auto!important;
  max-height: 350px!important;
}
.dropdown-item {
  cursor: pointer;
  white-space: normal;
}
// end Dropdown

// Card
.card.gray {
  border-radius: 32px;
  background: var(--main-gray-1, #F2F4F7);
}

.custom-card {
  border-bottom: 0;
  border-radius: 32px;
  background: #ffffff;
  padding: 24px;
}

.card-loader {
  border-radius: $customBorderRadius;
}
// end Card

// Cache card
.cache-btn {
  border-radius: $customBorderRadius;
}
// end Cache card

// Keyboard
.hg-button.hg-functionBtn.hg-enter {
  background: $blue;
  color: white;
}
// end Keyboard

// Accordion
.panel {

  .card {
    border-radius: 32px;
    @include media-breakpoint-down(sm) {
      border-radius: $customBorderRadius;
    }
  }

  .panel-heading {
    border-bottom: 0;
    border-radius: 32px;
    background: #ffffff;
    padding: 24px;
    @include media-breakpoint-down(sm) {
      border-radius: $customBorderRadius;
      padding: 16px;
    }

    .card-header {
      padding: 8px 24px;
    }

    .form-icon {
      width: 48px;
      height: 48px;
      @include media-breakpoint-down(sm) {
        width: 40px;
        height: 40px;
      }
    }

    .title {
      color: $asphalt;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      @include media-breakpoint-down(sm) {
        font-size: 14px;
        line-height: 22px
      }
    }

    .subTitle {
      color: $gray3;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      @include media-breakpoint-down(sm) {
        font-size: 14px;
        line-height: 22px
      }
    }

  }

  .panel-body.card-body {
    padding: 0 24px;
    @include media-breakpoint-down(sm) {
      padding: 0 16px;
    }
  }
}
// end Accordion

// Rating
.bs-rating-star {
  color: #E4E7EC;
  font-size: 18px;
  @include media-breakpoint-down(sm) {
    font-size: 15px;
  }

  &.active {
    color: #FFA717;
  }
}

// end Rating

// Popovers
.popover {
  --bs-popover-max-width: 312px;
}

.popover-content {
  position: relative;

  .license-img {
    width: 180px;
    height: 240px;
  }

  .license-img-horizontal {
    width: 280px;
    max-width: 280px;
    height: 176px;
  }
}

// end Popovers

// Offers
.offers-btn {
  height: 48px;
}

.offer {
  .main-btn {
    &:hover {
      .gift-icon {
        fill: $btn-primary-color !important;
      }
    }
  }

  .gift-icon {
    fill: $primary !important;
  }
}



.breadcrumb .breadcrumb-item {
  color: $gray3;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.main-title-item {
  color: $asphalt;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  @include media-breakpoint-down(sm) {
    font-size: 14px;
    line-height: 22px;

    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 99%;
  }
}

.white-space-normal {
  @include media-breakpoint-down(sm) {
    white-space: normal;
  }
}

.second-title-item {
  color: #6C757D;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.custom-second-title-item {
  font-size: 18px;
  line-height: 24px;
  color: #6C757D;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    line-height: 20px;
  }
}

.modal-dialog {
  .main-title-item {
    color: $asphalt;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 24px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 99%;
    }
  }

  .white-space-normal {
    @include media-breakpoint-down(sm) {
      white-space: normal;
    }
  }

  .second-title-item {
    color: #6C757D;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;

    @media (max-width: 576px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .custom-second-title-item {
    font-size: 25px;
    line-height: 30px;
    color: #6C757D;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.info-filter-block {

  h5 {
    @media (max-width: 576px) {
      font-size: 15px;
      max-width: 186px;
    }
  }

  .calendar-icon-btn {
    img {
      width: 32px;
      height: 32px;
      @include media-breakpoint-down(sm) {
        top: 18px;
        width: 24px;
        height: 24px;
      }
    }
  }

  img {
    width: 48px;
    height: 48px;
    @include media-breakpoint-down(sm) {
      width: 40px;
      height: 40px;
    }
  }
}

// Draft
.draft-btn {
  &:hover {
    .draft-icon {
      fill: $btn-primary-color !important;
    }
  }

  .draft-icon {
    fill: $primary !important;
  }
}

// Cross
.cross {
  border-radius: 16px;

  .form-switch {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: none;
    border-radius: $customBorderRadius;

    label {
      text-align: left;
      display: block;
      width: 100%;
      padding: 0px;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      border: none;
      transition: border-color 0.15s ease-in-out, box-shadow-sm 0.15s ease-in-out;
      height: auto;
      color: $asphalt;
      @include media-breakpoint-down(sm) {
        font-size: 12px;
        line-height: 18px;
        padding: 0;
      }
    }

    .form-check-input {
      width: 48px;
      height: 26px;
      margin: 0 15px 0 0 !important;
      &:focus {
        box-shadow: none;
      }
    }

    &.form-check-reverse {
      padding-right: 0 !important;
      padding-left: 0;
    }
  }

  .cross-description {
    color: $asphalt;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    @include media-breakpoint-down(sm) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .upsale-name {
    color: $asphalt;
    font-size: 14px;
    font-weight: 500;

    @include media-breakpoint-down(sm) {
      font-size: 10px;
    }

    svg {
      path {
        fill: $primary;
      }
    }
  }
}

// KBM
.driver-kbm {
  background: $bodyBg;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: $customBorderRadius;
  @include media-breakpoint-down(sm) {
    border-radius: $customBorderRadius;
    font-size: 12px;
  }

  .driver-kbm-name, .driver-kbm-values {
    display: flex;
    flex-direction: column;
  }

  .driver-kbm-values {
    text-align: right;
  }
}
// end Offers

// EDIT-DATA
.edit-box {
  border-top: 1px solid #E4E7EC;

  @include media-breakpoint-down(sm) {
    font-size: 13px;
  }

  h5 {
    @include media-breakpoint-down(sm) {
      font-size: 15px;
      max-width: 186px;
    }
  }

  img {
    @media (max-width: 576px) {
      width: 40px;
    }
  }

  .gray-string {
    color: #98A2B3;
    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  .edit-box-content {
    padding-left: 92px;
    padding-right: 24px;
    @include media-breakpoint-down(sm) {
      padding: 0 16px;
    }
  }

  .insurance-company-logo {
    width: 48px;
    height: 48px;
    border-radius: 16px;
    @include media-breakpoint-down(sm) {
      width: 40px;
      height: 40px;
      border-radius: 12px;
    }
  }

  .main-btn {
    height: 48px;
  }

  .btn-icon-edit {
    padding: 0;
    margin-right: 9px;
    height: auto;

    @include media-breakpoint-down(sm) {
      width: 30px;
      height: 30px;
      margin-right: 4px;
    }
  }
}
// end EDIT-DATA
