@import 'variables';
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

header {
  .logo {
    background-image: url(logo.svg);
    width: 260px;
    height: 38px;

    img {
      position: relative;
      left: 100px;
    }
  }

  h1 {
    font-family: 'Yandex Sans Display', sans-serif;
    font-size: 78px;
    font-weight: 800;
    line-height: 78px;
    letter-spacing: -1px;
    text-align: left;
    color: #444444;

    max-width: 410px;

    @include media-breakpoint-down(lg) {
      font-size: 40px;
      font-weight: 800;
      line-height: 40px;
      text-align: left;
    }
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #444;



    @include media-breakpoint-down(sm) {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

section {
  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
    }
  }
}

